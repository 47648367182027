import styled, { css } from 'styled-components';
import { stoomlinkColors as colors } from 'stoomlink-commons-ui/colors';
import CloseIcon from '../../assets/images/close-icon.svg';
import Button from '../Button';

export const Modal = styled.div`
  display: flex;
  place-content: center;
  place-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: hsla(0, 0%, 8%, 0.5);
  z-index: 10000;
`;

export const Box = styled.div`
  position: relative;
  margin: 16px;
  max-height: calc(100vh - 32px);
  width: 600px;
  background-color: ${({ theme }) => theme.colors.primary};
  box-sizing: border-box;
  overflow: auto;
`;


export const Container = styled.div`
  position: relative;
  display: block;
  ${({ isInline }) => isInline && css`margin-bottom: 64px;`}
  max-width: 960px;
`;

export const Wrapper = styled.div`
  ${({ isInline }) => !isInline && css`padding: 16px 16px 0;`}
`;

export const Content = styled.div`
  margin-bottom: 12px;
  ${({ isInline }) => !isInline && css`
    @media (min-width: 768px){
      padding-right: 96px;
    }
  `}
`;

export const Title = styled.div`
  margin-bottom: 22px;
  font-family: 'HN Display';
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.24px;
  line-height: 24px;
`;

export const Description = styled.div`
  margin-bottom: ${({ isSubmitted }) => isSubmitted ? '43px' : '8px'};
  font-size: ${({ isInline }) => isInline ? '16px' : '14px'};
  letter-spacing: 0.08px;
`;

export const Insider = styled.div`
  font-size: 14px;
  letter-spacing: 0.08px;
  opacity: 0.7;
`;

export const ButtonGroup = styled.div`
  display: flex;
  place-content: flex-end;
`;

export const StyledButton = styled(Button)`
  margin-top: ${({ isGrouped }) => isGrouped ? '43px' : '24px'};
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FieldLabel = styled.label`
  padding-bottom: 8px;
  font-size: 12px;
  color: ${() => colors.Gray700.hex};
`;

export const ErrorLabel = styled.div`
  padding-top: 3px;
  font-weight: 500;
  font-size: 12px;
  color: #E61A3C;
`;

export const FormError = styled.div`
  margin: 8px 0 0;
`;

export const Input = styled.input`
  position: relative;
  display: inline-flex;
  place-items: center;
  margin: 0;
  padding: 0 16px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.primaryShade1};
  border: 0;
  border-radius: 0;
  font-family: 'HN Text';
  font-size: 14px;
  letter-spacing: 0.08px;
  line-height: 22px;
  outline: transparent solid 2px;
  outline-offset: -2px;
  transition: background-color 0.25s cubic-bezier(.2, 0, .38, .9), border 0.25s cubic-bezier(.2, 0, .38, .9), color 0.25s cubic-bezier(.2, 0, .38, .9), outline 0.12s cubic-bezier(.2, 0, .38, .9);
  appearance: none;

  &::after {
    content: '';
    position: absolute;
    top: 2px;
    bottom: 2px;
    left: 2px;
    right: 2px;
    border: 1px solid transparent;
    pointer-events: none;
    transition: border 0.12s cubic-bezier(.2, 0, .38, .9);
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryShade2};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.primaryShade3};
    outline: ${({ theme }) => `${theme.colors.secondary} solid 2px`}
  }
  &:focus {
    background-color: ${({ theme, disabled }) => !disabled && theme.colors.primaryShade2};
    outline: ${({ disabled, theme }) => !disabled && `${theme.colors.secondary} solid 2px`};
    &::after {
      border: ${({ disabled, theme }) => !disabled && (`1px solid ${theme.colors.primary}`)};
    }
  }
  &[disabled] {
    background-color: ${({ theme }) => theme.colors.primaryShade2};
    color: ${() => colors.Gray300.hex};
    cursor: not-allowed;
  }
`;

export const Close = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  height: 48px;
  width: 48px;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  background-image: url(${CloseIcon});
  background-position: center;
  background-repeat: no-repeat;
  outline: transparent solid 2px;
  outline-offset: -2px;
  transition: outline 0.12s cubic-bezier(.2, 0, .38, .9);
  &:active {
    outline: ${({ theme }) => `${theme.colors.secondary} solid 2px`}
  }
  &:focus {
    outline: ${({ disabled, theme }) => !disabled && `${theme.colors.secondary} solid 2px`};
  }
`;

export const HoneyPot = styled.div`
  position: absolute;
  left: -9999px;
`;
