import React, { useState, useRef } from 'react';
import jsonp from 'fetch-jsonp';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import useOnClickOutside from 'stoomlink-commons-ui/hooks/useClickOutside';
import {
  Container, FieldContainer, FieldLabel, ErrorLabel, Wrapper, Close, Modal, HoneyPot,
  StyledButton, Input, Description, Insider, Title, Content, ButtonGroup, Box, FormError
} from './styledComponent';

const insiderWhiteList = [
  'nextmoov.be', 'stoomlink.com', 'belgianmobilitycompany.be',
  'letec.be',
  'belgiantrain.be',
  'stib.brussels', 'mivb.brussels',
  'delijn.be',
  'mobib.be', 'belgianmobib.be',
  'gilkinet.fed.be'
];

const NewsletterForm = (props) => {
  const { showTitle, isInline, onClose } = props;
  const [isSubmitted, setIsSubmitted] = useState(false);

  const modalRef = useRef();

  const sendForm = async (formData, setSubmitting, resetForm, isSubmitting, setStatus) => {
    if (isSubmitting) return null;
    // Honeypot
    if (formData.b_name !== '' || formData.b_email !== '' || formData.b_comment !== '') return null;

    const { b_name, b_email, b_comment, ...data } = formData;

    const params = new URLSearchParams({
      u: '3f8d53108f72a281f0ba94458',
      id: 'cd4dabc265',
      ...(insiderWhiteList.includes(data.EMAIL.split('@')[1]) ? { 'group[383113][1]': 1 } : {}),
      'gdpr[42394]': 'Y',
      ...data,
    });

    jsonp(`https://stoomlink.us1.list-manage.com/subscribe/post-json?${params.toString()}`, {
      jsonpCallback: 'c',
    })
      .then((r) => {
        return r.json();
      })
      .then((data) => {
        setSubmitting(false);
        if (data.result === 'success') {
          // Cleanup and die
          resetForm();
          setIsSubmitted(true);
        } else {
          // Error out
          if (data.msg.includes('already subscribed')) {
            // No need to tell, sweep under rug
            resetForm();
            setIsSubmitted(true);
          } else {
            console.info(data)
            setStatus({
              type: 'error',
              // If API error, display it
              ...(data.msg ? { message: data.msg } : {}),
            });
          }
        }
      })
      .catch((err) => {
        console.error('Signup failed', err);
        setSubmitting(false);
        setStatus({ type: 'error' });
      });
  }

  useOnClickOutside(modalRef, () => {
    if (isInline === false) onClose();
  });

  const ModalWrapper = (props) => {
    if (!isInline) {
      return (
        <Modal>
          <Box ref={modalRef}>
            {props.children}
          </Box>
        </Modal>
      );
    }
    return props.children;
  }

  return (
    <ModalWrapper>
      <Container isInline={isInline}>
        {
          !isInline && (
            <Close onClick={onClose} aria-label="Close dialog" />
          )
        }
        <Wrapper isInline={isInline}>
          <Content isInline={isInline}>
            {
              !isSubmitted ? (
                <>
                  {
                    showTitle && (
                      <Title>
                        Join our newsletter
                      </Title>
                    )
                  }
                  <Description isInline={isInline}>
                    Curious about what we will build for the future of transit in Belgium?<br />
                    Subscribe now and you'll read from us once a month.
                  </Description>
                  <Insider>
                    And if you work for NMBS-SNCB, STIB-MIVB, DeLijn or TEC, be sure to subscribe with your work e-mail as you'll automatically get exclusive insiders news too.
                  </Insider>
                </>
              ) : (
                <>
                  <Title>
                    Thank you for subscribing!
                  </Title>
                  <Description isInline={isInline} isSubmitted>
                    Keep an eye out on your inbox, you will soon hear from us, again.
                  </Description>
                </>
              )
            }
          </Content>
        </Wrapper>
        {
          !isSubmitted && (
            <Formik
              initialValues={{ EMAIL: '', b_name: '', b_email: '', b_comment: '' }}
              validate={(values) => {
                const errors = {};
                if (!values.EMAIL) {
                  errors.EMAIL = 'Required';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.EMAIL)
                ) {
                  errors.EMAIL = 'Invalid email address';
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting, resetForm, isSubmitting, setStatus }) => {
                setStatus(null);
                sendForm(values, setSubmitting, resetForm, isSubmitting, setStatus)
              }}
            >
              {({ isSubmitting, status }) => (
                <Form>
                  <Wrapper isInline={isInline}>
                    <HoneyPot
                      ariaLabel="Please leave the following three fields empty"
                    >
                      <FieldLabel htmlFor="b_name">
                        Name
                      </FieldLabel>
                      <Field
                        type="text"
                        id="b_name"
                        name="b_name"
                        placeholder="Mathieu"
                        tabIndex={-1}
                      />
                      <FieldLabel htmlFor="b_email">
                        Email
                      </FieldLabel>
                      <Field
                        type="text"
                        id="b_email"
                        name="b_email"
                        placeholder="honey@belgianmobilitycompany.be"
                        tabIndex={-1}
                      />
                      <FieldLabel htmlFor="b_comment">
                        Comment
                      </FieldLabel>
                      <Field
                        type="textarea"
                        id="b_comment"
                        name="b_comment"
                        placeholder="Leave a comment"
                        tabIndex={-1}
                      />
                    </HoneyPot>
                    <FieldContainer>
                      <FieldLabel htmlFor="email">
                        Your email address
                      </FieldLabel>
                      <Field
                        type="email"
                        id="email"
                        name="EMAIL"
                        as={Input}
                        placeholder="you@belgianmobilitycompany.be"
                      />
                      <ErrorMessage name="EMAIL" component={ErrorLabel} />
                    </FieldContainer>
                    {
                      status?.type === 'error' && (
                        <FormError>
                          <ErrorLabel>
                            {
                              status.message ? status.message : 'An error occured while submitting your subscription. Please try again.'
                            }
                          </ErrorLabel>
                        </FormError>
                      )
                    }
                  </Wrapper>
                  {
                    !isSubmitted && (
                      <>
                        {
                          isInline ? (
                            <StyledButton
                              type="submit"
                              disabled={isSubmitted}
                              isLoading={isSubmitting}
                            >
                              Sign up
                            </StyledButton>
                          ) : (
                            <ButtonGroup>
                              <StyledButton
                                type="button"
                                kind="secondary"
                                isGrouped
                                onClick={onClose}
                              >
                                Cancel
                              </StyledButton>
                              <StyledButton
                                type="submit"
                                disabled={isSubmitted}
                                isLoading={isSubmitting}
                                isGrouped
                              >
                                Sign up
                              </StyledButton>
                            </ButtonGroup>
                          )
                        }
                      </>
                    )
                  }
                </Form>
              )}
            </Formik>
          )
        }
      </Container>
    </ModalWrapper>
  );
}

NewsletterForm.defaultProps = {
  showTitle: true,
  isInline: false,
  onClose: () => { },
}

export default NewsletterForm;
